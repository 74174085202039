<template>
  <div id="contract">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "contract",
  data() {
    return {};
  },
  created() {
    document.title = "社区商城";
    let path = this.$route.query.path;
    let query = "";
    if (this.$route.query.query != "undefined") {
      query = this.$route.query.query;
    }

    if (!this.$utils.rule.empty(query)) {
      query = JSON.parse(decodeURIComponent(query));
    }
    console.log('query',query)
    if (path) {
      this.$router.replace({ path: "contract/" + path, query: query });
    }
  },
};
</script>
<style scoped>
#contract {
  width: 100%;
  height: 100%;
}
</style>
